import React, { useState, useEffect, useRef, useContext } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { useSnackbar } from "notistack"
import { SnackbarProvider } from "notistack"
import MuiAlert from "@material-ui/lab/Alert"
import { useTranslation } from "react-i18next"
import SiteContext from "../context/siteContext"
import InputPhone from "../components/helpers/input-phone"

import {
  Box,
  Tab,
  Tabs,
  Grid,
  Paper,
  AppBar,
  Select,
  Button,
  Divider,
  Snackbar,
  Backdrop,
  Container,
  TextField,
  makeStyles,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  ButtonGroup,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core"

import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/Clear"
import RemoveIcon from "@material-ui/icons/Remove"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import BookImage from "../../static/images/book-image.png"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import Transfer from "../../static/images/Icons/transfer.svg"
import WesternUnion from "../../static/images/Icons/western-union.svg"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const Checkout = ({ pageContext }) => {
  const classes = useStyles()
  const { pLang } = pageContext
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { setIsActiveCart } = useContext(SiteContext)
  const phoneRegexd = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{4})$/
  const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/

  const validateForm = useRef(null)
  const [loading, setLoading] = useState(false)
  const [tabValues, setTabValues] = useState(0)
  const [cartCount, setCartCount] = useState(0)
  const [countries, setCountries] = useState([])
  const [cartDiscount, setCartDiscount] = useState(0)
  const [shippingCost, setShippingCost] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [payType, setPayType] = useState("credit_card")
  const [currencyRateState, setCurrencyRate] = useState(1)
  const [loadingContent, setLoadingContent] = useState(true)
  const [cartItemContent, setCartItemContent] = useState([])
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
  const [cartCouponDiscount, setCartCouponDiscount] = useState(0)
  const [disabledBtnCoupon, setDisabledBtnCoupon] = useState(false)
  const [openAlertErrorOnSubmit, setOpenAlertErrorOnSubmit] = useState(false)
  const [checkoutFormPhone, setCheckoutFormPhone] = useState()
  const [openAlertCouponSuccess, setOpenAlertCouponSuccess] = useState({
    isOpen: false,
    message: "",
  })
  const [checkoutForm, setCheckoutForm] = useState({
    city: "",
    state: "",
    country: "",
    zipCode: "",
    yourNotes: "",
    yourAddress: "",
    phoneNumber: "",
    emailAddress: "",
    addressTitle: "",
    cardName: "",
    cardNumber: "",
    cardCVV: "",
    cardEndMonth: "",
    cardEndYear: "",
    shippingCost: 0,
    couponCode: "",
  })
  const [errorMassege, setErrorMassege] = useState({
    city: false,
    cityText: "",
    state: false,
    stateText: "",
    country: false,
    countryText: "",
    zipCode: false,
    zipCodeText: "",
    yourNotes: false,
    yourNotesText: "",
    phoneNumber: true,
    phoneNumberText: "",
    emailAddress: false,
    emailAddressText: "",
    addressTitle: false,
    addressTitleText: "",
    yourAddress: false,
    yourAddressText: "",
    cardEndYear: false,
    cardEndYearText: "",
    cardEndMonth: false,
    cardEndMonthText: "",
    cardCVV: false,
    cardCVVText: "",
    cardNumber: false,
    cardNumberText: "",
    cardName: false,
    cardNameText: "",
    couponCode: false,
    couponCodeText: "",
  })
  // const [paymentTypeOptions, setPaymentTypeOptions] = useState({
  //   payWestern: "",
  //   payEft: "",
  //   payWhatsapp: "",
  // })

  const handleChangeTabs = (event, newValue) => {
    setTabValues(newValue)
    setPayType(
      newValue === 0
        ? "credit_card"
        : newValue === 1
        ? "western_union"
        : newValue === 2
        ? "eft"
        : newValue === 3 && "whatsapp"
    )
  }

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "checkout",
        `/${i18n.language}/checkout`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "checkout",
          `/${i18n.language}/checkout`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/checkout`
      }
    })
  }, [i18n, pLang])

  useEffect(() => {
    if (windowGlobal) {
      if (windowGlobal.localStorage.getItem("currencyKey") === "TRY") {
        setCurrencyRate(windowGlobal.sessionStorage.getItem("currencyRate"))
      }
    }
  }, [])

  const currencyIcon = currencyRateState > 1 ? <>&#8378;</> : <>&#36;</>

  const handleClose = reason => {
    if (reason === "clickaway") {
      return
    }
    // setOpenAlertError(false)
    setOpenAlertSuccess(false)
    setOpenAlertErrorOnSubmit(false)
    setErrorMassege({ couponCode: false })
    setOpenAlertCouponSuccess({ isOpen: false })
  }

  const breadcrumbs = [
    {
      text: t("breadcrumbs.checkout"),
    },
  ]

  const enterdEmailAddress = () => {
    if (emailRegExp.test(checkoutForm.emailAddress)) {
      setErrorMassege({
        ...errorMassege,
        emailAddress: false,
        emailAddressText: t("errors.invalidEmailAddress"),
      })
      return true
    } else {
      setErrorMassege({
        ...errorMassege,
        emailAddress: true,
        emailAddressText: t("errors.invalidEmailAddress"),
      })
      return false
    }
  }

  const enterdCardNumber = () => {
    if (isSubmitted) {
      if (
        checkoutForm.cardNumber.length < 13 ||
        checkoutForm.cardNumber.length > 19
      ) {
        setErrorMassege({
          ...errorMassege,
          cardNumber: true,
          cardNumberText: t("errors.invalidPhoneNumber"),
        })
      }
    }
  }

  const handleChange = prop => event => {
    if (prop === "emailAddress" && isSubmitted) {
      enterdEmailAddress()
    } /* else if (prop === "phoneNumber" && isSubmitted) {
      enterdPhoneNumber()
    }  */ else if (
      prop === "cardNumber" &&
      isSubmitted
    ) {
      enterdCardNumber()
      // setErrorMassege({cardNumber: true, cardNumberText: "This feild is Required"})
    }
    setCheckoutForm({ ...checkoutForm, [prop]: event.target.value })
  }

  let jwtForProfile
  if (windowGlobal) {
    jwtForProfile = windowGlobal.sessionStorage.getItem("jwtCode")
  }

  let localCurrency =
    windowGlobal && windowGlobal.localStorage.getItem("currencyKey")

  const fetchCartData = () => {
    axios
      .get(`${process.env.GATSBY_API_URL}/get-cart-items-by-type/paper`, {
        headers: {
          Authorization: `Bearer ${jwtForProfile}`,
          "Accept-Language": i18n.language,
          currency: localCurrency,
        },
      })
      .then(res => {
        setCartCouponDiscount(res.data.couponeDiscount)
        setCartDiscount(res.data.discountAmount)
        setCartItemContent(res.data.items)
        setLoadingContent(false)
      })
  }

  useEffect(() => {
    fetchCartData()
  }, [cartCount, jwtForProfile, i18n, localCurrency])

  const handleDeleteItem = cartItemId => {
    axios
      .delete(`${process.env.GATSBY_API_URL}/cart-items/${cartItemId}`, {
        headers: { Authorization: `Bearer ${jwtForProfile}` },
      })
      .then(() => {
        setCartItemContent(
          cartItemContent.filter(item => item.id !== cartItemId)
        )
      })
  }

  let totalPrice = cartItemContent
    .map(item =>
      !item.couponeDiscountedPrice > 0
        ? item.itemDiscountPrice > 0
          ? item.itemDiscountPrice * item.quantity
          : item.itemPrice * item.quantity
        : item.couponeDiscountedPrice
    )
    .reduce((a, b) => a + b, 0)

  let totalAmount = cartItemContent
    .map(item => item.price)
    .reduce((a, b) => a + b, 0)

  const handleClickBadgeReduce = async itemId => {
    setLoading(true)

    let newCartItemContent = [...cartItemContent]
    let newCartItem = newCartItemContent
      .filter(item => item.id === itemId)
      .map(item => {
        return { ...item, quantity: Math.max(item.quantity - 1, 0) }
      })

    const res = await axios.put(
      `${process.env.GATSBY_API_URL}/cart-items/${itemId}`,
      { quantity: newCartItem[0].quantity },
      { headers: { Authorization: `Bearer ${jwtForProfile}` } }
    )

    let finalCartItemContent = newCartItemContent.map(item => {
      if (item.id === itemId) {
        return newCartItem[0]
      } else {
        return item
      }
    })

    setCartCount(cartCount + 1)
    setCartItemContent(finalCartItemContent)
    setLoading(false)
  }

  const handleClickBadgeIncrease = async itemId => {
    setLoading(true)

    let newCartItemContent = [...cartItemContent]
    let newCartItem = newCartItemContent
      .filter(item => item.id === itemId)
      .map(item => {
        return { ...item, quantity: Math.max(item.quantity + 1, 0) }
      })

    const res = await axios.put(
      `${process.env.GATSBY_API_URL}/cart-items/${itemId}`,
      { quantity: newCartItem[0].quantity },
      { headers: { Authorization: `Bearer ${jwtForProfile}` } }
    )

    let finalCartItemContent = newCartItemContent.map(item => {
      if (item.id === itemId) {
        return newCartItem[0]
      } else {
        return item
      }
    })

    setCartCount(cartCount - 1)
    setCartItemContent(finalCartItemContent)
    setLoading(false)
  }

  useEffect(() => {
    if (
      checkoutForm.country &&
      checkoutForm.city &&
      checkoutForm.yourAddress &&
      checkoutForm.zipCode
    ) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/get-shipping-cost`,
          {
            cartType: "paper",
            address: {
              country: checkoutForm.country,
              city: checkoutForm.city,
              addressText: checkoutForm.yourAddress,
              zip: checkoutForm.zipCode,
              // addressTitle: checkoutForm.addressTitle,
              state: checkoutForm.state,
              // notes: checkoutForm.yourNotes,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${jwtForProfile}`,
              currency:
                windowGlobal &&
                windowGlobal.localStorage.getItem("currencyKey"),
            },
          }
        )
        .then(res => {
          setShippingCost(res.data)
        })
    }
  }, [
    checkoutForm.country,
    checkoutForm.city,
    checkoutForm.yourAddress,
    checkoutForm.zipCode,
  ])
  if (documentGlobal) {
    if (documentGlobal.getElementById("cardCVV")) {
      documentGlobal
        .getElementById("cardCVV")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    if (documentGlobal.getElementById("cardNumber")) {
      documentGlobal
        .getElementById("cardNumber")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    if (documentGlobal.getElementById("cardEndMonth")) {
      documentGlobal
        .getElementById("cardEndMonth")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    if (documentGlobal.getElementById("cardEndYear")) {
      documentGlobal
        .getElementById("cardEndYear")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
    // if (documentGlobal.getElementById("zipCode")) {
    //   documentGlobal
    //     .getElementById("zipCode")
    //     .addEventListener("keypress", function (evt) {
    //       if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
    //         evt.preventDefault()
    //       }
    //     })
    // }
    if (documentGlobal.getElementById("phoneNumber")) {
      documentGlobal
        .getElementById("phoneNumber")
        .addEventListener("keypress", function (evt) {
          if (evt.key.charCodeAt() < 48 || evt.key.charCodeAt() > 57) {
            evt.preventDefault()
          }
        })
    }
  }

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/checkout-countries`, {
        headers: { "Accept-language": i18n.language },
      })
      .then(res => {
        setCountries(res.data)
      })
    // axios
    //   .get(`${process.env.GATSBY_API_URL}/options`, {
    //     headers: { "Accept-language": i18n.language },
    //   })
    //   .then(res => {
    //     setPaymentTypeOptions({
    //       ...paymentTypeOptions,
    //       payWestern: res.data.pay_western_union,
    //       payEft: res.data.pay_eft,
    //       payWhatsapp: res.data.pay_whatsapp,
    //     })
    //   })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    if (validateForm.current.checkValidity()) {
      setLoading(true)
      setDisabledBtn(true)
      setIsSubmitted(true)

      if (payType === "credit_card" && validateForm.current) {
        validateForm.current.submit()
        // setTimeout(() => {
        setLoading(false)
        setDisabledBtn(false)
        setIsSubmitted(false)
        // }, 3000)
      } else {
        axios
          .post(
            `${process.env.GATSBY_API_URL}/create-and-pay-order`,
            {
              cartType: "paper",
              payType: payType,
              buySource: "site",
              address: {
                country: checkoutForm.country,
                city: checkoutForm.city,
                addressText: checkoutForm.yourAddress,
                zip: checkoutForm.zipCode,
                // addressTitle: checkoutForm.addressTitle,
                state: checkoutForm.state,
                notes: checkoutForm.yourNotes,
              },
              contactInfo: {
                name: checkoutForm.firstName + checkoutForm.lastName,
                phone: checkoutFormPhone,
                email: checkoutForm.emailAddress,
              },
              cardName: checkoutForm.cardName,
              cardNumber: checkoutForm.cardNumber,
              cardCVV: checkoutForm.cardCVV,
              cardEndMonth: checkoutForm.cardEndMonth,
              cardEndYear: checkoutForm.cardEndYear,
            },
            { headers: { Authorization: `Bearer ${jwtForProfile}` } }
          )
          .then(res => {
            setOpenAlertSuccess(true)
            setDisabledBtn(false)
            setLoading(false)
            setTimeout(() => {
              setOpenAlertSuccess(false)
            }, 3000)
            setTimeout(() => {
              payType === "whatsapp"
                ? (windowGlobal.location.href = `https://wa.me/905418983688?text=${t(
                    "checkout.orderNumber"
                  )}${res.data.orderID}`)
                : navigate("/")
            }, 3500)
          })
      }
    } else {
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => {
        setOpenAlertErrorOnSubmit(false)
      }, 3000)
      if (!checkoutForm.firstName) {
        return setErrorMassege({
          firstName: true,
          firstNameText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.lastName) {
        return setErrorMassege({
          lastName: true,
          lastNameText: t("errors.requiredField"),
        })
      }
      if (!checkoutFormPhone) {
        return (
          // enterdPhoneNumber(),
          setErrorMassege({
            phoneNumber: true,
            phoneNumberText: t("errors.requiredField"),
          })
        )
      }
      if (!checkoutForm.emailAddress) {
        return (
          enterdEmailAddress(),
          setErrorMassege({
            emailAddress: true,
            emailAddressText: t("errors.requiredField"),
          })
        )
      }
      if (!checkoutForm.country) {
        return setErrorMassege({
          country: true,
          countryText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.city) {
        return setErrorMassege({
          city: true,
          cityText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.state) {
        return setErrorMassege({
          state: true,
          stateText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.zipCode) {
        return setErrorMassege({
          zipCode: true,
          zipCodeText: t("errors.requiredField"),
        })
      }
      // if (!checkoutForm.addressTitle) {
      //   return setErrorMassege({
      //     addressTitle: true,
      //     addressTitleText: t("errors.requiredField"),
      //   })
      // }
      if (!checkoutForm.yourAddress) {
        return setErrorMassege({
          yourAddress: true,
          yourAddressText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardName) {
        return setErrorMassege({
          cardName: true,
          cardNameText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardNumber) {
        return (
          enterdCardNumber(),
          setErrorMassege({
            cardNumber: true,
            cardNumberText: t("errors.requiredField"),
          })
        )
      }
      if (!checkoutForm.cardCVV) {
        return setErrorMassege({
          cardCVV: true,
          cardCVVText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardEndMonth) {
        return setErrorMassege({
          cardEndMonth: true,
          cardEndMonthText: t("errors.requiredField"),
        })
      }
      if (!checkoutForm.cardEndYear) {
        return setErrorMassege({
          cardEndYear: true,
          cardEndYearText: t("errors.requiredField"),
        })
      }
      setOpenAlertErrorOnSubmit(true)
      setTimeout(() => setOpenAlertErrorOnSubmit(false), 2000)
    }
  }

  const handleSubmitCoupon = () => {
    setDisabledBtnCoupon(true)
    if (checkoutForm.couponCode) {
      axios
        .post(
          `${process.env.GATSBY_API_URL}/apply-coupone`,
          {
            code: checkoutForm.couponCode,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtForProfile}`,
              "Accept-Language": i18n.language,
            },
          }
        )
        .then(res => {
          setDisabledBtnCoupon(false)
          setOpenAlertCouponSuccess({ isOpen: true, message: res.data.message })
          fetchCartData()
        })
        .catch(err => {
          setDisabledBtnCoupon(false)
          setErrorMassege({
            couponCode: true,
            couponCodeText: err.response.data.message,
          })
        })
    } else {
      setDisabledBtnCoupon(false)
      setErrorMassege({
        couponCode: true,
        couponCodeText: t("checkout.emptyCoupon"),
      })
    }
  }

  const isMobile = useMediaQuery("(max-width:960px)")

  useEffect(() => {
    setIsActiveCart(cartItemContent.length > 0 ? true : false)
  }, [handleClickBadgeReduce, cartItemContent])

  const valid = value => {
    if (value === "12345") {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <SEO title={t("breadcrumbs.checkout")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <Container className={classes.mainContainer}>
        {cartItemContent.length > 0 ? (
          <>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={6}
                className={`${classes.textCenter} ${classes.checkoutSidebar}`}
              >
                <Paper elevation={3} className={classes.checkoutPaperSideBar}>
                  {cartItemContent.map(item => (
                    <Grid
                      container
                      key={item.id}
                      className={classes.checkoutPaperGridSideBar}
                    >
                      <Grid item sm={3}>
                        {item.book.image ? (
                          <img
                            src={`${process.env.GATSBY_API_URL}${item.book.image.url}`}
                            alt="book"
                            className={classes.checkoutBookImageSideBar}
                          />
                        ) : (
                          <img
                            src={BookImage}
                            alt="book"
                            className={classes.checkoutBookImageSideBar}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        sm={7}
                        container
                        className={classes.headerPopoversCartDesktop}
                      >
                        <Grid item sm={12}>
                          <Typography>{item.book.bookTitle}</Typography>
                        </Grid>
                        <Divider
                          variant="middle"
                          className={classes.checkoutSidebarDivider}
                        />
                        <Grid item sm={6} xs={12}>
                          <Grid container>
                            <Grid item xs={6} sm={12}>
                              {t("cartItems.quantity")}:
                              <Paper
                                elevation={0}
                                className={classes.cartItemsCountPaper}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-evenly"
                                  alignItems="center"
                                  className={classes.countItemBox}
                                >
                                  <Box>
                                    <IconButton
                                      color="primary"
                                      aria-label="add to shopping cart"
                                      onClick={() =>
                                        handleClickBadgeReduce(
                                          item.id,
                                          item.quantity
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Box>
                                  <Box className={classes.cartItemsCountnumber}>
                                    {item.quantity}
                                    <Backdrop
                                      className={classes.backdrop}
                                      open={loading}
                                    >
                                      <CircularProgress color="inherit" />
                                    </Backdrop>
                                  </Box>
                                  <Box>
                                    <IconButton
                                      color="primary"
                                      aria-label="add to shopping cart"
                                      onClick={() =>
                                        handleClickBadgeIncrease(
                                          item.id,
                                          item.quantity
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Paper>
                            </Grid>
                            {isMobile && (
                              <Grid item xs={6} sm={false}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{ marginTop: 10 }}
                                >
                                  {t("checkout.delete")}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography>
                            {t("cartItems.total")}:{" "}
                            {item.itemDiscountPrice
                              ? (
                                  item.itemDiscountPrice * item.quantity
                                ).toFixed(2)
                              : (item.itemPrice * item.quantity).toFixed(2)}
                            {currencyIcon}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={2}>
                        {!isMobile && (
                          <IconButton
                            aria-label="delete"
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteItem(item.id)}
                            className={classes.margin}
                          >
                            <ClearIcon fontSize="large" />
                          </IconButton>
                        )}
                      </Grid>
                      <Divider
                        variant="middle"
                        className={classes.checkoutSidebarDivider}
                      />
                    </Grid>
                  ))}
                  <div
                    className={`${classes.textAlignLeft} ${classes.totalDataSideBar}`}
                  >
                    <div>
                      <Typography variant="h5" component="p">
                        {t("cartItems.subTotal")}:
                      </Typography>
                      <Typography variant="h5" component="p">
                        {t("cartItems.shippingCost")}:
                      </Typography>
                      <Typography variant="h5" component="p">
                        {t("checkout.discount")}:
                      </Typography>
                      <Typography variant="h5" component="p">
                        {t("cartItems.total")}:
                      </Typography>
                    </div>
                    <div style={{ direction: "ltr" }}>
                      <Typography variant="h5" component="p">
                        {totalAmount.toFixed(2) /* .toFixed(2) */}
                        {currencyIcon}
                      </Typography>
                      <Typography variant="h5" component="p">
                        {shippingCost && shippingCost > 0 && shippingCost}{" "}
                        {currencyIcon}
                      </Typography>
                      <Typography variant="h5" component="p">
                        {(
                          (cartDiscount && cartDiscount) +
                          (cartCouponDiscount && cartCouponDiscount)
                        ).toFixed(2)}
                        {currencyIcon}
                      </Typography>
                      <Typography variant="h5" component="p">
                        {shippingCost
                          ? (totalPrice + shippingCost).toFixed(2)
                          : totalPrice.toFixed(2)}
                        {currencyIcon}
                      </Typography>
                    </div>
                  </div>
                </Paper>
                <br />
                <Paper elevation={3} className={classes.checkoutPaperSideBar2}>
                  <form>
                    <Grid container>
                      <Grid item xs={12} className={classes.textCenter}>
                        <Typography variant="subtitle1" component="h3">
                          {t("checkout.couponTitle")}
                        </Typography>
                      </Grid>
                      <Grid item sm={8} xs={12}>
                        <FormControl
                          fullWidth
                          className={`${classes.margin} ${classes.formConrolSignup}`}
                        >
                          <TextField
                            id="coupon"
                            name="coupon"
                            type="text"
                            label={t("checkout.couponTitle")}
                            variant="outlined"
                            onChange={handleChange("couponCode")}
                            error={errorMassege.couponCode}
                            helperText={errorMassege.couponCodeText}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <ButtonGroup
                          color="primary"
                          aria-label="Order"
                          disabled={disabledBtnCoupon}
                          onClick={handleSubmitCoupon}
                          className={`${classes.margin} button`}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            className={classes.orderBookBtn}
                          >
                            {disabledBtnCoupon && (
                              <CircularProgress size={26} />
                            )}
                            {!disabledBtnCoupon && t("checkout.applyCoupon")}
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textCenter}>
                <form
                  ref={validateForm}
                  id="validateForm"
                  method="post"
                  // onSubmit={handleSubmit()}
                  action={`${
                    payType === "credit_card"
                      ? "https://gwthani.com/sendPayment.php"
                      : ""
                  }`}
                >
                  <input type="hidden" value={jwtForProfile} name="userToken" />
                  <input type="hidden" value={payType} name="paymentType" />
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className={classes.boldFont}
                  >
                    {t("checkout.personalDetails")}
                  </Typography>
                  <FormControl
                    fullWidth
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                  >
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      type="text"
                      label={t("checkout.firstName")}
                      variant="outlined"
                      onChange={handleChange("firstName")}
                      error={errorMassege.firstName}
                      helperText={errorMassege.firstNameText}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                  >
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      type="text"
                      label={t("checkout.lastName")}
                      variant="outlined"
                      onChange={handleChange("lastName")}
                      error={errorMassege.lastName}
                      helperText={errorMassege.lastNameText}
                    />
                  </FormControl>
                  <InputPhone
                    phoneValue={setCheckoutFormPhone}
                    error={errorMassege.phoneNumber}
                    errorText={errorMassege.phoneNumberText}
                  />
                  <FormControl
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                    fullWidth
                  >
                    <TextField
                      required
                      id="emailAddress"
                      name="emailAddress"
                      label={t("checkout.emailAddress")}
                      variant="outlined"
                      error={errorMassege.emailAddress}
                      helperText={errorMassege.emailAddressText}
                      onChange={handleChange("emailAddress")}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    className={classes.boldFont}
                  >
                    {t("checkout.AddressInfos")}
                  </Typography>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                  >
                    <InputLabel htmlFor="countryLabel">
                      {t("checkout.country")}
                    </InputLabel>
                    <Select
                      native
                      required
                      name="country"
                      label="countryLabel"
                      value={checkoutForm.country}
                      onChange={handleChange("country")}
                      error={errorMassege.country}
                      helpertext={errorMassege.countryText}
                    >
                      <option aria-label="None" value="" />
                      {countries &&
                        countries.map(item => (
                          <option key={item.code} value={item.code}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                  >
                    <TextField
                      required
                      id="city"
                      name="city"
                      type="text"
                      label={t("checkout.city")}
                      variant="outlined"
                      onChange={handleChange("city")}
                      error={errorMassege.city}
                      helperText={errorMassege.cityText}
                    />
                  </FormControl>
                  <FormControl
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                    fullWidth
                  >
                    <TextField
                      required
                      id="state"
                      name="state"
                      label={t("checkout.state")}
                      variant="outlined"
                      error={errorMassege.state}
                      helperText={errorMassege.stateText}
                      onChange={handleChange("state")}
                    />
                  </FormControl>
                  <FormControl
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                    fullWidth
                  >
                    <TextField
                      required
                      id="zipCode"
                      name="zipCode"
                      label={t("checkout.zipCode")}
                      variant="outlined"
                      error={errorMassege.zipCode}
                      helperText={errorMassege.zipCodeText}
                      onChange={handleChange("zipCode")}
                    />
                  </FormControl>
                  {/* <FormControl
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                    fullWidth
                  >
                    <TextField
                      required
                      id="addressTitle"
                      name="addressTitle"
                      label={t("checkout.addressTitle")}
                      variant="outlined"
                      error={errorMassege.addressTitle}
                      helperText={errorMassege.addressTitleText}
                      onChange={handleChange("addressTitle")}
                    />
                  </FormControl> */}
                  <FormControl
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                    fullWidth
                  >
                    <TextField
                      required
                      id="yourAddress"
                      name="yourAddress"
                      label={t("checkout.yourAddress")}
                      variant="outlined"
                      error={errorMassege.yourAddress}
                      helperText={errorMassege.yourAddressText}
                      onChange={handleChange("yourAddress")}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={`${classes.margin} ${classes.formConrolSignup}`}
                  >
                    <TextField
                      id="yourNotes"
                      multiline
                      name="yourNotes"
                      rows={4}
                      label={t("orderBook.yourNotes")}
                      variant="outlined"
                      value={checkoutForm.yourNotes}
                      onChange={handleChange("yourNotes")}
                    />
                  </FormControl>
                  <br />
                  <br />

                  <AppBar position="static" color="default">
                    <Tabs
                      value={tabValues}
                      onChange={handleChangeTabs}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable force tabs example"
                    >
                      <Tab
                        label="CreditCard"
                        className={classes.paymentTypesTabs}
                        icon={
                          <CreditCardIcon
                            className={classes.paymentTypeTabsIcon}
                          />
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Western Union"
                        className={classes.paymentTypesTabs}
                        icon={
                          <img
                            src={WesternUnion}
                            className={classes.paymentTypeTabsIcon}
                            alt="tabs icon"
                          />
                        }
                        {...a11yProps(1)}
                      />
                      <Tab
                        label="Transfer"
                        className={classes.paymentTypesTabs}
                        icon={
                          <img
                            src={Transfer}
                            className={classes.paymentTypeTabsIcon}
                            alt="tabs icon"
                          />
                        }
                        {...a11yProps(2)}
                      />
                      <Tab
                        label="WhatsApp"
                        className={classes.paymentTypesTabs}
                        icon={
                          <WhatsAppIcon
                            className={classes.paymentTypeTabsIcon}
                          />
                        }
                        {...a11yProps(3)}
                      />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tabValues} index={0}>
                    <Typography
                      variant="subtitle1"
                      component="h3"
                      className={classes.boldFont}
                    >
                      {t("checkout.paymentDetails")}
                    </Typography>
                    <FormControl
                      fullWidth
                      className={`${classes.margin} ${classes.formConrolSignup}`}
                    >
                      <TextField
                        required
                        id="cardName"
                        name="cardName"
                        type="text"
                        label={t("checkout.cardName")}
                        variant="outlined"
                        onChange={handleChange("cardName")}
                        error={errorMassege.cardName}
                        helperText={errorMassege.cardNameText}
                      />
                    </FormControl>
                    <FormControl
                      fullWidth
                      className={`${classes.margin} ${classes.formConrolSignup}`}
                    >
                      <TextField
                        required
                        id="cardNumber"
                        name="cardNumber"
                        type="text"
                        label={t("checkout.cardNumber")}
                        variant="outlined"
                        onChange={handleChange("cardNumber")}
                        error={errorMassege.cardNumber}
                        helperText={errorMassege.cardNumberText}
                      />
                    </FormControl>
                    <Grid container>
                      <Grid item xs={4} className={classes.paddingLeftRight}>
                        <FormControl
                          fullWidth
                          className={`${classes.margin} ${classes.formConrolSignup}`}
                        >
                          <TextField
                            required
                            id="cardCVV"
                            name="cardCVV"
                            type="password"
                            label={t("checkout.cardCVV")}
                            variant="outlined"
                            onChange={handleChange("cardCVV")}
                            error={errorMassege.cardCVV}
                            helperText={errorMassege.cardCVVText}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} className={classes.paddingLeftRight}>
                        <FormControl
                          className={`${classes.margin} ${classes.formConrolSignup}`}
                          fullWidth
                        >
                          <TextField
                            required
                            id="cardEndMonth"
                            name="cardMonth"
                            label={t("checkout.cardEndMonth")}
                            variant="outlined"
                            error={errorMassege.cardEndMonth}
                            helperText={errorMassege.cardEndMonthText}
                            onChange={handleChange("cardEndMonth")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} className={classes.paddingLeftRight}>
                        <FormControl
                          className={`${classes.margin} ${classes.formConrolSignup}`}
                          fullWidth
                        >
                          <TextField
                            required
                            id="cardEndYear"
                            name="cardEndYear"
                            label={t("checkout.cardEndYear")}
                            variant="outlined"
                            error={errorMassege.cardEndYear}
                            helperText={errorMassege.cardEndYearText}
                            onChange={handleChange("cardEndYear")}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValues} index={1}>
                    <Typography component="p">
                      {t("checkout.pay-with-western")}
                      <br />
                      YAMAN GAVSANİ
                      <br />
                      00905536996948
                    </Typography>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: paymentTypeOptions.payWestern,
                      }}
                    /> */}
                  </TabPanel>
                  <TabPanel value={tabValues} index={2}>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-1")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-2")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-3")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-4")}
                    </Typography>
                    <Typography component="p">
                      <a href="https://wa.me/905418983688">00905418983688⁣⁣</a>
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-5")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-6")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-7")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-8")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-9")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-10")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-11")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-12")}
                    </Typography>
                    <Typography component="p">
                      {t("checkout.pay-with-eft-line-13")}
                    </Typography>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: paymentTypeOptions.payEft,
                      }}
                    /> */}
                  </TabPanel>
                  <TabPanel value={tabValues} index={3}>
                    <Typography component="p">
                      {t("checkout.pay-with-whatsapp")}
                      <br />
                      {/* <a href="https://wa.me/905418983688⁣⁣">
                        00905418983688⁣⁣
                      </a> */}
                    </Typography>
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: paymentTypeOptions.payWhatsapp,
                      }}
                    /> */}
                  </TabPanel>
                </form>
                <ButtonGroup
                  color="primary"
                  aria-label="Order"
                  disabled={disabledBtn}
                  onClick={handleSubmit}
                  className={`${classes.orderBtn} ${classes.margin} button`}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.orderBookBtn}
                  >
                    {disabledBtn && <CircularProgress size={26} />}
                    {!disabledBtn && t("orderBook.orderBookBtn")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </>
        ) : (
          <div>
            {loadingContent ? (
              <Typography align="center">{t("myProfile.loading")}</Typography>
            ) : (
              <Typography align="center">{t("cartItems.noContent")}</Typography>
            )}
          </div>
        )}
      </Container>
      <SnackbarProvider maxSnack={3}>
        <Snackbar
          open={openAlertSuccess}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {t("checkout.orderCompleted")}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openAlertCouponSuccess.isOpen}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {t("checkout.couponApplied")}
          </Alert>
        </Snackbar>
        {/* <Snackbar
          open={openAlertError}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMassegeCatch}
          </Alert>
        </Snackbar> */}
        <Snackbar
          open={openAlertErrorOnSubmit}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {t("checkout.wrongEntry")}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMassege.couponCode}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMassege.couponCodeText}
          </Alert>
        </Snackbar>
      </SnackbarProvider>
    </>
  )
}
export default Checkout

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paddingLeftRight: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
  formConrolSignup: {
    padding: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& input, select": {
      padding: "10px 12px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  orderBookBtn: {
    height: 40,
    borderRadius: 10,
  },
  textCenter: {
    textAlign: "center",
    "& > .MuiTypography-root": {
      // marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      fontSize: 26,
    },
  },
  checkoutSidebar: {
    margin: "2rem 0",
    padding: "0rem 3rem !important",
  },
  checkoutBookImageSideBar: {
    maxWidth: "100%",
  },
  checkoutPaperGridSideBar: {
    display: "flex",
    alignItems: "center",
    padding: "14px",
  },
  checkoutSidebarDivider: {
    width: "80%",
    margin: "12px auto",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  totalDataSideBar: {
    maxWidth: "80%",
    margin: "0 auto",
    display: "flex",
    textAlign: "left",
    justifyContent: "space-between",
    "& div:last-child": {
      direction: "ltr",
    },
  },
  checkoutPaperSideBar: {
    padding: "16px 0 16px 12px",
  },
  checkoutPaperSideBar2: {
    padding: "16px 12px",
  },
  orderBtn: {
    marginTop: 14,
    width: "100%",
    textAlign: "center",
    "& > .MuiButtonBase-root": {
      width: 250,
      margin: "0 auto",
      maxWidth: "100%",
    },
  },
  confirmSmsDialog: {
    "& .MuiPaper-root.MuiDialog-paper": {
      width: "100%",
      height: "50%",
    },
  },
  confirmSmsEmbed: {
    width: "100%",
    height: "100%",
  },
  paymentTypeTabsIcon: {
    fontSize: 32,
    width: 32,
    height: 32,
  },
  paymentTypesTabs: {
    minWidth: 130,
  },
  inputCopy: {
    border: 0,
    color: "#000",
    width: 88,
    backgroundColor: "transparent",
  },
  tabPanels: {
    // "& *": {
    textAlign: "left",
    // },
  },
  boldFont: {
    fontWeight: "bold",
    textAlign: "center",
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function TabPanel(props) {
  const classes = useStyles()
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} className={classes.tabPanels}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  }
}
